import { Alert } from "react-bootstrap";
import { parseSymbol } from "../../shared/utils";

export function BookingCost(props) {
  const {total, vat, subTotal, currency = '$'} = props;

  return (
    <div className="flex-column">
      <Alert className="booking-cost d-flex flex-column" variant="light">

        {
          vat > 0 &&
          <div>
            <strong>SubTotal</strong>: <span>{parseSymbol(currency)}</span> {subTotal} 
          </div>
        }
        {
          vat > 0 && 
          <div>
            <strong>VAT</strong>: {vat} %
          </div>
        }
        <div>
          <strong>TOTAL</strong>: {parseSymbol(currency)} {total}
        </div>
      </Alert>
    </div>
  );
}
