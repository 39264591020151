import { Alert } from "react-bootstrap";
import { parseSymbol } from "../../shared/utils";

export function BookingCost(props) {
  const { vat, total, subTotal, currency = '$'} = props;
  return (
      <Alert className="booking-cost d-flex flex-column" variant="light">
        {
          vat > 0 &&
          <div>
            <strong>SubTotal</strong>: <span>{parseSymbol(currency)}</span> {subTotal} 
          </div>
        }
        {
          vat > 0 && 
          <div>
            <strong>VAT</strong>: {vat} %
          </div>
        }
        <div>
          <strong>TOTAL</strong>: <span>{parseSymbol(currency)}</span> {total}
        </div>
      </Alert>
  );
}
