import { useQuery } from "react-query";
import { getBaseUrl } from "../../shared/utils";

const useConferenceRooms = (location) => {
  const baseUrl = getBaseUrl();
  return useQuery(
    ["conferenceRooms", location],
    async () => {
      const data = await fetch(
        `${baseUrl}/external-meetingroom?cmd=getConferenceRooms&locationid=${location}`
      ).then((response) => response.json());
      return data;
    },
    {
      enabled: !!location,
    }
  );
};
export default useConferenceRooms;
