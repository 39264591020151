import { useQuery } from "react-query";
import { getBaseUrl } from "../shared/utils";

export function useDataDayPassLocations({} = {}) {
  const baseUrl = getBaseUrl();
  return useQuery("daypass-locations", async () => {
    const data = await fetch(
      `${baseUrl}/external-daypass?cmd=getLocations`
    ).then((response) => response.json());
    return data;
  });
}

export function useDataMeetingRoolLocations({} = {}) {
  const baseUrl = getBaseUrl();
  return useQuery("locations", async () => {
    const data = await fetch(
      `${baseUrl}/external-meetingroom?cmd=getLocations`
    ).then((response) => response.json());
    return data;
  });
}
