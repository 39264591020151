import { Col, Container, Row, Table, Button, Spinner, Alert } from "react-bootstrap";
import { useQuery } from "react-query";
import axiosInstance from "../shared/axios-instance";
import { getBaseUrl, getQueryParam } from "../shared/utils";
import { TelephoneFill, EnvelopeFill, GeoAltFill } from 'react-bootstrap-icons';
import { useToasts } from 'react-toast-notifications'
import { parseSymbol } from "../shared/utils";


const totalWithoutVat = (total, vat) => {
  if (vat > 0 ) {
    const vatAmount = total * (vat / 100);
    const totalWithoutVat = total - vatAmount;
    return totalWithoutVat.toFixed(2);
  }
  return total;
}
const Invoice = () => {
  const baseUrl = getBaseUrl();
  const { addToast } = useToasts()
  const invoiceId = getQueryParam('invoiceId');
  const authToken = getQueryParam('authToken');
  const { isLoading, error, data: settingsData } = useQuery("whitelabelSettingsBasic", async () => {
    if (!baseUrl) {
      throw new Error("Please set client to redirect to related marketplace");
    }
    return fetch(`${baseUrl}/settings`).then((res) => res.json());
  });
  const logoSrc = settingsData && settingsData.logo_dark;
  const companyName = settingsData && settingsData.company;

  const { isLoading: isLoadingInvoice, error: invoiceError, data, refetch } = useQuery(['invoice', invoiceId], async () => {
    if (!baseUrl) {
      throw new Error("Please set client to redirect to related marketplace");
    }
    return fetch(`${baseUrl}/invoices/${invoiceId}?auth_token=${authToken}&cmd=getFullInvoiceData`).then((res) => res.json());
  });
  const submitPayment = async () => {
    try {
      const response = await axiosInstance.put(
        `/invoices/${invoiceId}`,
        {
          cmd: 'submitMemberPayment',
          auth_token: authToken
        }
      )
      addToast('Your Invoice Paid Successfully', { appearance: 'success' })
      refetch();
    }
    catch (e) {
      addToast(e.response.data.message, { appearance: 'error' })
      refetch();
    }
  }
  if (error || invoiceError) {
    return (
      <Alert variant="danger">
        An Error Happen while loading the invoice details.
      </Alert>
    );
  }
  if (isLoading || isLoadingInvoice) {
    return (
      <div className="landing-spinner">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }
  return (
    <div style={{ backgroundColor: '#c9c9c9', height: '100%', paddingTop: '100px', paddingBottom: '100px', }}>
      <Container fluid="md">
        <Col style={{ backgroundColor: '#e7e5e8', borderRadius: '10px', height: '100%' }}>
          <Row style={{ paddingTop: '40px' }}>
            <Col>
              {logoSrc && <img src={logoSrc} height="80" />}
              <h3>{companyName}</h3>
            </Col>
            <Col>
              <h2 style={{ color: '#305D7A', fontWeight: 'bold', fontSize: '70px' }}>Invoice</h2>
              <h6 style={{ fontWeight: 'bold' }}>Invoice NO. <span style={{ color: '#305D7A' }} >#{data?.invoice?.id}</span></h6>
            </Col>
          </Row>
          <Row style={{ marginTop: '50px' }}>
            <Col sm={{ span: 12 }} lg={{ span: 6, offset: 6 }}>
              <Row>
                <Col style={{ fontWeight: 'bold' }}>Invoice Date:</Col>
                <Col>{data?.invoice?.created_at}</Col>
              </Row>
              <Row>
                <Col style={{ fontWeight: 'bold' }}>Status:</Col>
                <Col>{data?.invoice?.status}</Col>
              </Row>
              <Row>
                <Col style={{ fontWeight: 'bold' }}>Payment Due:</Col>
                <Col>{data?.invoice?.payment_date}</Col>
              </Row>
            </Col>
          </Row>
          <Col style={{ marginBottom: '30px', marginTop: '30px' }}>
            <Row style={{ fontWeight: 'bold', fontSize: '12px' }}>To</Row>
            <Row><b>{data?.member?.name}</b></Row>
            <Row style={{ fontWeight: 'bold', fontSize: '12px' }}>{data?.member?.company}</Row>
            <Row style={{ marginTop: '10px' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <GeoAltFill color="#305D7A" /><span style={{ marginLeft: '10px' }}>{data?.member?.address}</span>
              </div>
            </Row>
            <Row>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <EnvelopeFill color="#305D7A" /><span style={{ marginLeft: '10px' }}>{data?.member?.email}</span>
              </div>
            </Row>
            <Row>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <TelephoneFill color="#305D7A" /><span style={{ marginLeft: '10px' }}>{data?.member?.phone}</span>
              </div>
            </Row>
          </Col>
          <Row>
            <Table striped hover>
              <thead style={{ backgroundImage: 'linear-gradient(to right, #3c8dbc, #305D7A)' }}>
                <tr style={{ color: 'white' }}>
                  <th>Description</th>
                  <th>Accounting Code</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {
                  data?.offices.map((office, i) => {
                    return <tr key={`office${i}`}>
                      <td>{office.name}</td>
                      <td>{office.accounting_code}</td>
                      <td>{office.rate}{parseSymbol(data?.invoice?.currency_symbol || '$')}</td>
                    </tr>
                  })
                }
                {
                  data?.lines.map((line, i) => {
                    return <tr key={`line${i}`}>
                      <td>{line.description}</td>
                      <td>{line.accounting_code}</td>
                      <td>{line.amount}{parseSymbol(data?.invoice?.currency_symbol || '$')}</td>
                    </tr>
                  })
                }

              </tbody>
            </Table>
          </Row>
          <Row style={{ marginTop: '50px' }}> <Col md={{ span: 3, offset: 8 }}>
            <Row>
              <Col style={{ fontWeight: 'bold' }}> Sub Total:</Col>	<Col>{parseSymbol(data?.invoice?.currency_symbol || '$')}{data?.invoice?.rate}</Col>
            </Row>
            <Row>
              <Col style={{ fontWeight: 'bold' }}> VAT:</Col>	<Col>{data?.invoice?.vat || 0} %</Col>
            </Row>
            <Row>
              <Col style={{ fontWeight: 'bold' }}> Total:</Col>	<Col>{parseSymbol(data?.invoice?.currency_symbol || '$')}{data?.invoice?.payment_required}</Col>
            </Row>
            <Row>
              <Col style={{ fontWeight: 'bold' }}> Paid:</Col>	<Col>{parseSymbol(data?.invoice?.currency_symbol || '$')}{data?.invoice?.payment_paid}</Col>
            </Row>
            <Row>
              <Col style={{ fontWeight: 'bold' }}> Due:</Col>	<Col>{parseSymbol(data?.invoice?.currency_symbol || '$')}{data?.invoice?.payment_due}</Col>
            </Row>
            <Row style={{ marginTop: '20px', marginBottom: '20px' }}>
              <Col> <Button size="lg" onClick={submitPayment} style={{ backgroundColor: '#305D7A' }} block variant="success">Pay Now</Button></Col>
            </Row>
          </Col></Row>
        </Col>
      </Container>
    </div>)
}
export default Invoice;