const formatTimePeriodList = (timeList) => {
  let mappedTimeList = new Map();
  for (const startTime in timeList) {
    const endTimeList = timeList[startTime].map(getFormatedTime);
    mappedTimeList.set(getFormatedTime(startTime), endTimeList);
  }
  return mappedTimeList
}
const getBaseUrl = () => {
  const searchQuery = new URLSearchParams(window.location.search);
  const whitelabel = searchQuery.get("client");
  if (whitelabel) {
    const baseUrl = `https://${whitelabel}.pickspace.com/api/app`
    // const baseUrl = 'http://localhost:3001/api/app';
    return baseUrl
  }
}
const getQueryParam = (param) => {
  const searchQuery = new URLSearchParams(window.location.search);
  const value = searchQuery.get(param);
  return value;

}
const getFormatedTime = (minutes) => {
  let hours = Math.floor(minutes / 60);
  let remainingMinute = minutes - (hours * 60);
  let timeCycle = 'AM';
  if (hours === 0) {
    hours = 12;
  }
  else if (hours === 12) {
    timeCycle = 'PM'
  }
  else if (hours > 12) {
    hours = hours - 12;
    timeCycle = 'PM'
  }
  remainingMinute = remainingMinute < 10 ? `0${remainingMinute}` : remainingMinute
  hours = hours < 10 ? `0${hours}` : hours

  return `${hours}:${remainingMinute} ${timeCycle}`;
}
const convertTimeToMinute = (time) => {
  let splitedTime = time.split(' ')
  const timePeriod = splitedTime[1];
  splitedTime = splitedTime[0].split(':')

  let hours = parseInt(splitedTime[0]);
  let minutes = parseInt(splitedTime[1]);
  hours = hours === 12 ? 0 : hours;
  if (timePeriod === 'PM') {
    hours = hours + 12
  }
  return hours * 60 + minutes
}
const paymentMethodTypes = {
  "credit_card": {
    value: 'CC',
    text: 'Credit Card'
  },
  "check_ach": {
    value: 'ACH',
    text: 'Check Ach'
  }
}

export function getIsoDate(date) {
  const dateStr = date?.toISOString?.().split?.("T");
  if (!dateStr) {
    return date;
  }
  return dateStr[0];
}

export const parseSymbol = (str) => {
  const parser = new DOMParser();
  return parser.parseFromString(`${str}`, 'text/html').body.textContent;
};

export {
  formatTimePeriodList,
  convertTimeToMinute,
  getBaseUrl,
  getQueryParam,
  paymentMethodTypes
}
