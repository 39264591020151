import { Form, Row, Col, Button } from "react-bootstrap";

import { LocationSelectbox } from "../../components/location/location-selectbox";
import { ConferenceRoomSelectbox } from "./conference-room-selectbox";
import { BookingCost } from "./booking-cost";
import useLocations from './use-locations';
import getFreePeriods, { getBookingCost } from './use-free-periods';
import useConferenceRooms from './use-conference-rooms';
import { useCallback, useState, useEffect, useRef } from "react";
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
const dateFormat = 'YYYY-MM-DD';



export function BookingForm(props) {
  const { onSubmit } = props;

  const { data: locations } = useLocations();
  const [bookingForm, setBookingForm] = useState({
    fromTime: null,
    toTime: null,
    conferenceRoom: null,
    location: null,
    date: null,
    countryCode: null,
  })
  const { data: conferenceRooms } = useConferenceRooms(bookingForm.location);
  const [bookingCost, setBookingCost] = useState(0)

  const [freePeriods, setFreePeriods] = useState([]);

  const dateRef = useRef(null);
  const fromTimeRef = useRef(null);
  const dateStyle = { 
    width: '100%',
    padding: '0.375rem 0.75rem',
  };

  useEffect(() => {
    if (bookingForm.fromTime && bookingForm.toTime && bookingForm.conferenceRoom && bookingForm.location && bookingForm.date) {
      getBookingCost(bookingForm).then((res) => {
        setBookingCost(res);
      });
    }
    if (bookingForm.conferenceRoom && bookingForm.date) {
      getFreePeriods(bookingForm.conferenceRoom, bookingForm.date).then(periods => {
        setFreePeriods(periods)
      })
    }
  }, [bookingForm, bookingForm.fromTime, bookingForm.toTime, bookingForm.conferenceRoom, bookingForm.location, bookingForm.date])

  const onLocationChange = useCallback(
    (location) => {
      setBookingCost(0);
      dateRef.current.focus();
      fromTimeRef.current.value = 'default';
      const { id, countryCode, symbol: currency } = location;
      setBookingForm({ ...bookingForm, location: id, countryCode, currency })
    },
    [],
  )
  const onFromTimeChange = (event) => {
    setBookingForm({ ...bookingForm, fromTime: event.target.value })
  }
  const onBookingDateChange = (date) => {
    if (date && date.format) {
      setBookingForm({ ...bookingForm, date: date.format(dateFormat) })
    }
  }
  const onToTimeChange = (event) => {
    setBookingForm({ ...bookingForm, toTime: event.target.value })
  }
  const onConferenceRoomChange = (event) => {
    setBookingForm({ ...bookingForm, conferenceRoom: event.target.value })
  }
  const onSubmitBookingForm = () => {
    onSubmit(bookingForm);
  }

  return (
    <Form>
      <LocationSelectbox onLocationChange={onLocationChange} locations={locations} />
      <Form.Group as={Row} controlId="bookingDateTime">
        <Form.Label column sm="2">
          Date
        </Form.Label>
        <Col sm="4">
          <DatePicker 
            ref={dateRef}
            style={dateStyle}
            onChange={onBookingDateChange} 
            placeholder="Select Date"
            format={dateFormat}
            name="date"
          />
        </Col>
        <Col sm="3">
          <Form.Control as="select" ref={fromTimeRef} name="fromTime" placeholder='select from date' defaultValue='default' onChange={onFromTimeChange}>
            <option value='default' >select value</option>

            {
              freePeriods && [...freePeriods.keys()].map((startTime, index) =>
                <option key={index} value={startTime} >{startTime}</option>
              )
            }
          </Form.Control>
        </Col>

        <Col sm="3">
          <Form.Control as="select" name="toTime" defaultValue='default' onChange={onToTimeChange}>
            <option value='default' >select value</option>
            {
              freePeriods && bookingForm.fromTime && bookingForm.fromTime !== 'default' && freePeriods.get(bookingForm.fromTime).map((time, index) =>
                <option key={index} value={time} >{time}</option>
              )
            }
          </Form.Control>
        </Col>

      </Form.Group>
      <ConferenceRoomSelectbox onConferenceRoomChange={onConferenceRoomChange} conferenceRooms={conferenceRooms} />
      <BookingCost subTotal={bookingCost.subTotal} total={bookingCost.total} vat={bookingCost.vat} currency={bookingForm.currency} />
      <Button
        disabled={bookingCost.total === 0}
        variant="primary"
        className="btn-submit"
        onClick={onSubmitBookingForm}
      >
        Next
      </Button>
    </Form>
  );
}
