import { Navbar } from "react-bootstrap";

import styles from "./styles.css";

import { useQueryClient } from "react-query";

export function TopNav(props) {
  const { title } = props;
  const queryClient = useQueryClient();
  const settings = queryClient.getQueryData('whitelabelSettingsBasic');
  const logoSrc = settings && settings.logo_dark;

  return (
    <Navbar bg="light" variant="light">
      <Navbar.Brand>
        {logoSrc && <img src={logoSrc} height="40" />}
        {title}
      </Navbar.Brand>
    </Navbar>
  );
}
