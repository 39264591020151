import React, { useState, useEffect } from "react";

import { Form, Row, Col, Button } from "react-bootstrap";

import { LocationSelectbox } from "../../components/location/location-selectbox";
import { BookingCost } from "../../components/booking-cost";

import { useDataDayPassLocations } from "../../data/use-locations";

import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useDayPassCost } from "./use-daypass-cost";

const schema = yup.object().shape({
  date: yup.array().of(yup.date()).min(1),
  quantity: yup.number().required().positive().integer().moreThan(0),
});

export function DayPassForm(props) {
  const { onSubmit } = props;
  const [locationId, setLocationId] = useState();
  const [countryCode, setCountryCode] = useState();

  const { data: locations } = useDataDayPassLocations();
  const { register, handleSubmit, errors, control } = useForm({
    resolver: yupResolver(schema),
  });
  const quantity = useWatch({ control, name: "quantity", defaultValue: 1 });
  const date = useWatch({ control, name: "date" });
  const cost = useDayPassCost({
    locationId,
    quantity,
  });
  const handleDayPassSubmit = (booking) =>
    onSubmit?.({ ...booking, locationId, countryCode });
  const [currency, setCurrency] = useState("");

  return (
    <Form onSubmit={handleSubmit(handleDayPassSubmit)}>
      <LocationSelectbox locations={locations} onLocationChange={(location) => {
        setLocationId(location.id);
        setCountryCode(location.countryCode)
        setCurrency(location.symbol)
      }} />
      <Form.Group as={Row} controlId="daypassQuantity">
        <Form.Label column sm={2}>
          Quantity
        </Form.Label>
        <Col sm={10}>
          <Form.Control
            ref={register}
            type="number"
            placeholder="Quantity?"
            name="quantity"
            isInvalid={errors?.quantity}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="daypassDate">
      {

        new Array(quantity ? parseInt(quantity): 1).fill(1).map(
          (index,value)=> {
                return (<>
                  <Form.Label column sm={2}>
                    Date #{value+1}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      ref={register}
                      type="date"
                      placeholder="Booking date"
                      name={"date["+value+"]"}
                    />
                  </Col>
                  </>);
              }
      )
    }
      </Form.Group>
      <BookingCost total={cost.total || 0} subTotal={cost.subTotal || 0} vat={cost.vat || 0} currency={currency} />
      <Button variant="primary" className="btn-submit" type="submit">
        Next
      </Button>
    </Form>
  );
}
