import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from 'react-query/devtools'

import { MarketPlaceLanding } from "./containers/marketplace-landing";
import { ToastProvider } from 'react-toast-notifications'
import Invoice from './invoice'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import './App.css'

// import { QueryClientFactory } from "./shared/query-client-factory";
const queryClient = new QueryClient()
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ToastProvider>
        <ReactQueryDevtools initialIsOpen={false} />
        <Router>
          <Switch>
            <Route path="/invoice">
              <Invoice />
            </Route>
            <Route path="/">
              <MarketPlaceLanding />
            </Route>
          </Switch>
        </Router>
      </ToastProvider>
    </QueryClientProvider>
  );
}

export default App;
