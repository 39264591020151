import { Form, Row, Col, Button } from "react-bootstrap";
import 'react-phone-number-input/style.css'
import PhoneInput,  { isValidPhoneNumber } from 'react-phone-number-input'
import { useEffect, useState } from "react";

import './styles.css'

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import "yup-phone";
const schema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  company: yup.string().required(),
  phonenumber: yup.string().phone().required(),
  ein: yup.number().nullable().notRequired().transform((_, val) => (val !== "" ? Number(val) : null)),
});
export function MemberForm(props) {
  const { onSubmit } = props;
  const { register, handleSubmit, errors, control } = useForm({
    resolver: yupResolver(schema)
  });

  const [countryCode, setCountryCode] = useState('US');

  useEffect( () => {
    fetch('https://ipapi.co/json/').then(response => response.json()).then((data) => data.country).then(setCountryCode);
  }, [])

  return (
    <Form>
      <div>
      </div>
      <Form.Group as={Row} controlId="memberName">
        <Form.Label column sm="2">
          Name
        </Form.Label>
        <Col sm="10">
          <Form.Control
            isInvalid={errors.name}
            type="text"
            placeholder="Personal name"
            name="name"
            ref={register}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="memberEmail">
        <Form.Label column sm="2">
          Email
        </Form.Label>
        <Col sm="10">
          <Form.Control
            type="email"
            isInvalid={errors.email}
            placeholder="Personal email"
            name="email"
            ref={register}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="memberCompany">
        <Form.Label column sm="2">
          Company
        </Form.Label>
        <Col sm="10">
          <Form.Control
            type="text"
            isInvalid={errors.company}

            placeholder="Personal company"
            name="company"
            ref={register}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="ein">
        <Form.Label column sm="2">
          EIN
        </Form.Label>
        <Col sm="10">
          <Form.Control
            isInvalid={errors.ein}
            placeholder="EIN"
            name="ein"
            type="number"
            ref={register}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="memberPhone">
        <Form.Label column sm="2">
          Phone
        </Form.Label>
        <Col sm="10">
          <Controller 
            name="phonenumber"
            control={control}
            rules={{
              validate: (value) => isValidPhoneNumber(value)
            }}
            render={({ onChange }) => {
              return <>
                <PhoneInput placeholder="Phone number" defaultCountry={countryCode} onChange={onChange} />
                { errors.phonenumber && <span className="text-danger">Invalid phone number</span> }
              </>;
            }}
          />
        </Col>
      </Form.Group>
      <Button
        variant="primary"
        className="btn-submit"
        onClick={handleSubmit(onSubmit)}
      >
        Next
      </Button>
    </Form>
  );
}
