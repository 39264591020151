import { useEffect, useState } from "react";

import { getBaseUrl } from "../../shared/utils";

const fetchCost = async ({ locationId, quantity }) => {
  const baseUrl = getBaseUrl();
  const data = await fetch(
    `${baseUrl}/external-daypass?cmd=getBookingCost&locationId=${locationId}&quantity=${quantity}`
  ).then((response) => response.json());
  return data;
};

export const useDayPassCost = ({ locationId, quantity } = {}) => {
  const [cost, setCost] = useState({ total: 0, vat: 0, subTotal: 0, originalCost: 0});
  useEffect(() => {
    if (!locationId || !quantity) {
      return;
    }
    fetchCost({ locationId, quantity }).then(setCost);
  }, [locationId, quantity]);

  return { ...cost, currency: cost?.currency };
};
