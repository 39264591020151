import { useState } from "react";
import { CardDeck, Card, Container, Button } from "react-bootstrap";
import { TopNav } from "../../components/top-nav";

import { BookConferenceRoom } from "../book-conference-room";
import { BookDayPass } from "../book-day-pass";

import { useQueryClient } from "react-query";

import styles from "./styles.css";

export function MarketplaceOptions(props) {
  const queryClient = useQueryClient();
  const settings = queryClient.getQueryData('whitelabelSettingsBasic');
  const isDayPassEnabled = settings && parseInt(settings.allow_external_daypass) === 1;

  const [bookingType, setBookingType] = useState();

  if (bookingType === 1) {
    return <BookConferenceRoom />;
  }

  if (bookingType === 2) {
    return <BookDayPass />;
  }

  return (
    <>
      <TopNav title="What do you want to book ?" />
      <Container className="marketplace-options">
        <CardDeck>
          <Card>
            <Card.Body>
              <Card.Title>Meeting rooms</Card.Title>
              {/* <Card.Text>continue with meeting rooms booking</Card.Text> */}
              <Button variant="primary" onClick={() => setBookingType(1)}>
                BOOK NOW
              </Button>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <Card.Title>Day Pass</Card.Title>
              <Button variant="primary" disabled={!isDayPassEnabled} onClick={() => setBookingType(2)}>
                BOOK NOW
              </Button>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <Card.Title>Units</Card.Title>
              {/* <Card.Text>Coming soon</Card.Text> */}
              <Button variant="primary" disabled>
                COMING SOON
              </Button>
            </Card.Body>
          </Card>
        </CardDeck>
      </Container>
    </>
  );
}
