import { Media, Row, Col, Form, Card } from "react-bootstrap";
import { useEffect } from "react";

function ConferenceRoomSelectOption(props) {
  const { room, onConferenceRoomChange } = props;


  return (
    <Row className="conference-room-option">
      <Col xs="auto">
        <Form.Check
          onChange={onConferenceRoomChange}
          type="radio"
          id={`conference-room-${room.id}`}
          value={room.id}
          name="conferenceRoom"
        />
      </Col>
      <Col>
        <Media>
          <img
            alt="company logo"
            width={64}
            height={64}
            src={
              room.image_link ||
              "https://res.cloudinary.com/http-www-pickspace-net/image/upload/wcbko65tj9jvokiwnctp"
            }
          />
          <Media.Body>
            <h5>{room.name}</h5>
            <p>Capacity: {room.max_people_number || "..."}</p>
          </Media.Body>
        </Media>
      </Col>
    </Row>
  );
}
export function ConferenceRoomSelectbox(props) {
  const { onConferenceRoomChange, conferenceRooms } = props;

  useEffect(() => {
    if (conferenceRooms && conferenceRooms[0]) {
      onConferenceRoomChange({ target: { value: conferenceRooms[0].id } });
    }
  }, [conferenceRooms]);
  return (
    <Form.Group as={Card}>
      <Card.Body>
        {conferenceRooms &&
          conferenceRooms.map((room, index) => (
            <ConferenceRoomSelectOption
              room={room}
              key={index}
              onConferenceRoomChange={onConferenceRoomChange}
            />
          ))}
      </Card.Body>
    </Form.Group>
  );
}
