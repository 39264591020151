import { useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";

export function useBookingSteps({ bookingInfo, onSubmit } = {}) {
  const { addToast } = useToasts();
  const [step, setStep] = useState(1);
  const [activeTab, setActiveTab] = useState("personal");

  const [memberInfo, setMemberInfo] = useState(null);
  const [paymentInfo, setPaymentInfo] = useState(null);
  const [bookingResult, setBookingResult] = useState(null);

  const handleMemberSubmit = (member) => {
    setMemberInfo(member);
    setActiveTab("booking");
    if (step < 2) {
      setStep(2);
    }
  };

  const handleBookingSubmit = (booking) => {
    setActiveTab("payment");
    if (step < 3) {
      setStep(3);
    }
  };

  useEffect(() => {
    if (!bookingInfo) {
      return;
    }
    handleBookingSubmit();
  }, [bookingInfo]);

  const handlePaymentSubmit = async (payment) => {
    if (payment?.expiraryMonth?.length === 1) {
      payment.expiraryMonth = `0${payment.expiraryMonth}`;
    }
    if (payment.expiraryMonth && payment.expiraryYear) {
      payment.expiraryDate = `${payment.expiraryMonth}${payment.expiraryYear}`;
    }
    setPaymentInfo(payment);
    setBookingResult("LOADING");
    try {
      const response = await onSubmit?.({
        member: memberInfo,
        payment,
        booking: bookingInfo,
      });
      addToast(response?.data?.message, { appearance: "success" });
      setBookingResult({ status: "SUCCESS" });
    } catch (e) {
      addToast(e?.response?.data?.message, { appearance: "error" });
      setBookingResult({ status: "FAILD" });
    }
  };

  return {
    step,
    activeTab,
    setActiveTab,
    handleMemberSubmit,
    handlePaymentSubmit,
    bookingResult,
  };
}
