import React, { useState } from "react";

import axiosInstance from "../../shared/axios-instance";
import { getIsoDate } from "../../shared/utils";


export function useDayPassBooking({} = {}) {
  const [locationId, setLocationId] = useState();
  const [booking, setBooking] = useState();
  const [location, setLocation] = useState();

  const handleBookingSubmit = ({ locationId, ...booking }) => {
    setLocationId(locationId);
    setBooking(booking);
  };

  const handleAllStepsSubmit = async ({ member, payment, booking }) => {
    const payload = {
      member_info: member,
      payment_info: payment,
      booking_info: {
        ...booking,
        location: locationId,
        date: booking.date,
      },
    };
    for (var i=0;i<payload.booking_info.date.length;i++) {
      payload.booking_info.date[i]=getIsoDate(payload.booking_info.date[i]);
    }
    console.log("Submitting Data now", payload);
    const response = await axiosInstance.post("/external-daypass", payload);
    return response;
  };

  return {
    handleBookingSubmit,
    handleAllStepsSubmit,
    locationId,
    booking,
    setLocation,
  };
}
