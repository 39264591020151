import {
  useQuery,
} from "react-query";
import { getBaseUrl } from "../../shared/utils";


export const useLocationPaymentMethods = (locationId) => {
  const baseUrl = getBaseUrl()
  return useQuery("locationPaymentMethod", async () => {
    const data = await fetch(
      `${baseUrl}/external-daypass?cmd=getLocationPaymentMethods&location=${locationId}`
    ).then(response => response.json());
    return data;
  },
    {
      initialData: [],
      enabled: !!locationId
    }
  );
}
