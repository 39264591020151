import { TopNav } from "../top-nav";

import { Tab, Row, Col, Nav } from "react-bootstrap";

import { MemberForm } from "../member-info/member-form";
import { PaymentForm } from "../payment/payment-form";
import { BookingSuccess } from "./booking-success";

import { useBookingSteps } from "./use-booking-steps";

import styles from "./styles.css";

export function BookingSteps(props) {
  const { locationId, bookingInfo, onSubmit } = props;

  const {
    step,
    activeTab,
    setActiveTab,
    handleMemberSubmit,
    handlePaymentSubmit,
    bookingResult,
  } = useBookingSteps({ bookingInfo, onSubmit });

  if (bookingResult?.status === "SUCCESS") {
    return <BookingSuccess />;
  }

  return (
    <div className="booking-steps">
      <Tab.Container
        id="booking-tabs"
        transition={false}
        defaultActiveKey="personal"
        activeKey={activeTab}
        onSelect={setActiveTab}
      >
        <Row>
          <Col sm={3}>
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link eventKey="personal">Personal Info</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="booking" disabled={step < 2}>
                  Booking Info
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="payment" disabled={step < 3}>
                  Payment Info
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={9}>
            <Tab.Content>
              <Tab.Pane eventKey="personal">
                <MemberForm onSubmit={handleMemberSubmit} />
              </Tab.Pane>
              <Tab.Pane eventKey="booking">{props.children}</Tab.Pane>
              <Tab.Pane eventKey="payment">
                <PaymentForm
                  onSubmit={handlePaymentSubmit}
                  bookingInfo={{ location: locationId }}
                  bookingResult={bookingResult}
                />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
}
