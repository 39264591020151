import { Container, Alert, Card } from "react-bootstrap";

export function BookingSuccess() {
  return (
    <Container>
      <Alert variant="success" className="booking-success">
        <h1>Booking Successfully!</h1>
        <p>Thank you for your booking, and see you soon.</p>
      </Alert>
    </Container>
  );
}
