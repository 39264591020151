import { useEffect, useState } from "react";
import { Form, Row, Col } from "react-bootstrap";

export function LocationSelectbox(props) {
  const { onLocationChange, locations } = props;
  const [selectedLocation, setSelectedLocation] = useState(null)

  const onChange = (event) => {
    const location = locations?.find(location => location.id === event.target.value);
    setSelectedLocation(location);
    onLocationChange(location);
  }
  useEffect(() => {
    if (locations && locations[0]) {
      setSelectedLocation(locations[0]);
      onLocationChange(locations[0]);
    }
  }, [locations])
  return (
    <Form.Group as={Row} controlId="memberLocation">
      <Form.Label column sm="2">
        Location
      </Form.Label>
      <Col sm="3">
        <Form.Control as="select" name="location" onChange={onChange}>
          {
            locations?.map(location =>
              <option key={location.id} value={location.id}>{location.name}</option>
            )
          }
        </Form.Control>
      </Col>
      <Col sm="7">
        {selectedLocation && <span>{selectedLocation.address}</span>}
      </Col>
    </Form.Group>
  );
}
