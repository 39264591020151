import { TopNav } from "../../components/top-nav";

import { Tab, Row, Col, Nav } from "react-bootstrap";

import { MemberForm } from "../../components/member-info/member-form";
import { BookingForm } from "./booking-form";
import { PaymentForm } from "../../components/payment/payment-form";
import { BookingSuccess } from "./booking-success";

import { useBookingSteps } from "./use-booking-steps";

import styles from "./styles.css";
import { ToastMessage } from "../../components/toast";

export function BookConferenceRoom(props) {
  const {
    step,
    activeTab,
    setActiveTab,
    handleMemberSubmit,
    handleBookingSubmit,
    handlePaymentSubmit,
    bookingResult,
    bookingInfo
  } = useBookingSteps();

  if (bookingResult?.status === 'SUCCESS') {
    return (
      <>
        <TopNav title="Book our rooms" />
        <BookingSuccess />
      </>
    );
  }

  return (
    <>
      {/* { bookingResult && bookingResult.status === 'FAILD' ? < ToastMessage /> : <></>} */}
      <TopNav title="Book our rooms" />
      <div className="booking-steps">
        <Tab.Container
          id="booking-tabs"
          transition={false}
          defaultActiveKey="personal"
          activeKey={activeTab}
          onSelect={setActiveTab}
        >
          <Row>
            <Col sm={3}>
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="personal">Personal Info</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="booking" disabled={step < 2}>
                    Booking Info
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="payment" disabled={step < 3}>
                    Payment Info
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={9}>
              <Tab.Content>
                <Tab.Pane eventKey="personal">
                  <MemberForm onSubmit={handleMemberSubmit} />
                </Tab.Pane>
                <Tab.Pane eventKey="booking">
                  <BookingForm onSubmit={handleBookingSubmit} />
                </Tab.Pane>
                <Tab.Pane eventKey="payment">
                  <PaymentForm onSubmit={handlePaymentSubmit} bookingInfo={bookingInfo} bookingResult={bookingResult} />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </>
  );
}
