import React from "react";

import { TopNav } from "../../components/top-nav";
import { BookingSteps } from "../../components/booking-steps";

import { useDataDayPassLocations } from "../../data/use-locations";

import { DayPassForm } from "./daypass-form";

import { useDayPassBooking } from "./use-daypass-booking";

import styles from "./styles.css";

export function BookDayPass(props) {
  const { data: locations } = useDataDayPassLocations();
  const {
    handleBookingSubmit,
    handleAllStepsSubmit,
    locationId,
    booking,
  } = useDayPassBooking();

  return (
    <>
      <TopNav title="Book Day Pass" />
      <BookingSteps
        locationId={locationId}
        bookingInfo={booking}
        onSubmit={handleAllStepsSubmit}
      >
        <DayPassForm onSubmit={handleBookingSubmit} />
      </BookingSteps>
    </>
  );
}
