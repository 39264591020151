import {
  useQuery,
} from "react-query";
import { getBaseUrl } from "../../shared/utils";

const useLocations = () => {
  const baseUrl = getBaseUrl()
  return useQuery("locations", async () => {
    const data = await fetch(
      `${baseUrl}/external-meetingroom?cmd=getLocations`
    ).then(response => response.json());
    return data;
  });
}

export default useLocations;