import { useState, useRef } from "react";
import { convertTimeToMinute, getBaseUrl } from '../../shared/utils'
import { useToasts } from 'react-toast-notifications'
import axiosInstance from '../../shared/axios-instance'
export function useBookingSteps() {
  const [bookingResult, setBookingResult] = useState(null);
  const { addToast } = useToasts()
  const baseUrl = getBaseUrl();
  const [step, setStep] = useState(1);
  const [activeTab, setActiveTab] = useState("personal");

  const [memberInfo, setMemberInfo] = useState(null);
  const [bookingInfo, setBookingInfo] = useState(null);
  const [paymentInfo, setPaymentInfo] = useState(null);

  const handleMemberSubmit = (member) => {
    setMemberInfo(member);
    setActiveTab("booking");
    if (step < 2) {
      setStep(2);
    }
  };

  const handleBookingSubmit = (booking) => {
    setBookingInfo(booking);
    setActiveTab("payment");
    if (step < 3) {
      setStep(3);
    }
  };
  function handleErrors(res) {
    console.log(res)
    if (!res.ok) throw new Error(res.error);
    return res;
  }

  const handlePaymentSubmit = async (payment) => {
    if (payment.expiraryMonth.length === 1) {
      payment.expiraryMonth = `0${payment.expiraryMonth}`;
    }
    payment.expiraryDate = `${payment.expiraryMonth}${payment.expiraryYear}`
    setPaymentInfo(payment);
    setBookingResult('LOADING');
    let bookingData = { ...bookingInfo };
    bookingData.fromTime = convertTimeToMinute(bookingInfo.fromTime)
    bookingData.toTime = convertTimeToMinute(bookingInfo.toTime)
    const payload = {
      member_info: memberInfo,
      payment_info: payment,
      booking_info: bookingData
    }
    try {
      const response = await axiosInstance.post(
        '/external-meetingroom',
        payload
      )
      addToast(response.data.message, { appearance: 'success' })

      setBookingResult({ status: 'SUCCESS' })
    }
    catch (e) {
      addToast(e.response.data.message, { appearance: 'error' })
      setBookingResult({ status: 'FAILD' })
    }
  };

  return {
    step,
    activeTab,
    setActiveTab,
    handleMemberSubmit,
    handleBookingSubmit,
    handlePaymentSubmit,
    bookingResult,
    bookingInfo
  };
}
