import {
  useQuery,
} from "react-query";
import { formatTimePeriodList, getBaseUrl, convertTimeToMinute } from '../../shared/utils'
const getFreePeriods = async (conferenceRoomId, bookingDate) => {
  const baseUrl = getBaseUrl()
  const data = await fetch(
    `${baseUrl}/external-meetingroom?cmd=getFreePeriods&conference_roomid=${conferenceRoomId}&booking_date=${bookingDate}`
  ).then(response => response.json());
  return formatTimePeriodList(data)

}
export const getBookingCost = async (bookingInfo) => {
  const baseUrl = getBaseUrl()
  const data = await fetch(
    `${baseUrl}/external-meetingroom?cmd=getBookingCost&fromTime=${convertTimeToMinute(bookingInfo.fromTime)}&toTime=${convertTimeToMinute(bookingInfo.toTime)}&
      conferenceRoom=${bookingInfo.conferenceRoom}&date=${bookingInfo.date}`
  ).then(response => response.json());
  return data;
}

export default getFreePeriods;