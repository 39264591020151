import { useQuery } from "react-query";

import { MarketplaceOptions } from "../markerplace-options";
import { Alert, Spinner, CardDeck, Card, Container } from "react-bootstrap";

import styles from "./styles.css";
import { getBaseUrl } from "../../shared/utils";

export function MarketPlaceLanding() {
  const baseUrl = getBaseUrl();
  const { isLoading, error } = useQuery("whitelabelSettingsBasic", async () => {
    if (!baseUrl) {
      throw new Error("Please set client to redirect to related marketplace");
    }
    return fetch(`${baseUrl}/settings`).then((res) => res.json());
  });

  if (error) {
    return (
      <Alert variant="danger">
        Please set client to redirect to related marketplace
      </Alert>
    );
  }

  if (isLoading) {
    return (
      <div className="landing-spinner">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  return <MarketplaceOptions />;
  // return <BookConferenceRoom />;
}
