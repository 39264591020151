import { useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";

import { useForm } from "react-hook-form";
import { useLocationPaymentMethods } from "./use-payment-method";
import { paymentMethodTypes } from "../../shared/utils"
import cardValidator from "card-validator"
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
export function PaymentForm(props) {
  const { onSubmit, bookingResult, bookingInfo } = props;
  const schema = yup.object().shape({
    paymentType: yup.string().required(),
    cardNumber: yup.string().when('paymentType', {
      is: 'CC',
      then: yup.string().test('test-number',
        'Credit Card number is invalid',
        value => cardValidator.number(value).isValid)
        .required()
    }),
    expiraryMonth: yup.string().when('paymentType', {
      is: 'CC',
      then: yup.string().test('test-number',
        'Credit Card number is invalid',
        value => cardValidator.expirationMonth(value).isValid)
        .required()
    }),
    memberName: yup.string().when('paymentType', {
      is: 'CC',
      then: yup.string().required()
    }),
    expiraryYear: yup.string().when('paymentType', {
      is: 'CC',
      then: yup.string().test('test-number',
        'expirary Year number is invalid',
        value => cardValidator.expirationYear(value).isValid)
        .required()
    }),
    cvc: yup.string().when('paymentType', {
      is: 'CC',
      then: yup.string().test('test-number',
        'cvv number is invalid',
        value => cardValidator.cvv(value).isValid)
        .required()
    }),
    // zip: yup.string().when('paymentType', {
    //   is: 'CC',
    //   then: yup.string().required()
    // }),
    // billingAddress: yup.string().when('paymentType', {
    //   is: 'CC',
    //   then: yup.string().required()
    // }),
    accountingNumber: yup.string().when('paymentType', {
      is: 'ACH',
      then: yup.string().required()
    }),
    routingNumber: yup.string().when('paymentType', {
      is: 'ACH',
      then: yup.string().required()
    })
  });
  const { register, handleSubmit, errors } = useForm(
    {
      resolver: yupResolver(schema)
    }
  );
  const [paymentType, setPaymentType] = useState(null);
  const { data: locationPaymentMethods } = useLocationPaymentMethods(bookingInfo?.location);
  const onChangePaymentType = (event) => {
    setPaymentType(event.target.value);
  }
  return (
    <Form>
      <Form.Group as={Row} controlId="paymentType">
        <Form.Label column sm="2">
          Payment Type
        </Form.Label>
        <Col sm="10">
          <Form.Control as="select" name="paymentType" ref={register} defaultValue={null} onChange={onChangePaymentType}>
            <option value={null} >select value</option>
            {
              locationPaymentMethods?.map?.(locationPaymentMethod => {
                return (
                  <option value={paymentMethodTypes[locationPaymentMethod].value} key={paymentMethodTypes[locationPaymentMethod].value}>{paymentMethodTypes[locationPaymentMethod].text}</option>
                )
              })
            }
          </Form.Control>
        </Col>
      </Form.Group>
      {      paymentType === "CC" ?

        <div>
          <Form.Group key="cardNumber" as={Row} controlId="cardNumber">
            <Form.Label column sm="2">
              Credit Card Number
        </Form.Label>
            <Col sm="10">
              <Form.Control
                type="text"
                isInvalid={errors.cardNumber}
                placeholder="Credit card number"
                name="cardNumber"
                ref={register}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} key="memberName" controlId="memberName">
            <Form.Label column sm="2">
              Name
            </Form.Label>
            <Col sm="10">
              <Form.Control
                type="text"
                isInvalid={errors.memberName}
                placeholder="Name on credit card"
                name="memberName"
                ref={register}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} key="cvc" controlId="cvc">
            <Form.Label column sm="2">
              CVC
            </Form.Label>
            <Col sm="10">
              <Form.Control
                isInvalid={errors.cvc}
                type="text"
                placeholder="CVC"
                name="cvc"
                ref={register}
              />
            </Col>
          </Form.Group>
            { bookingInfo?.countryCode !== 'IL' && 
             <Form.Group as={Row} key="billingAddress" controlId="billingAddress">
                <Form.Label column sm="2">
                  Billing Address
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    isInvalid={errors.billingAddress}
                    type="text"
                    placeholder="Billing Address"
                    name="billingAddress"
                    ref={register}
                  />
                </Col>
              </Form.Group>
            }
            { bookingInfo?.countryCode !== 'IL' && 
              <Form.Group as={Row} key="zip" controlId="zip">
                <Form.Label column sm="2">
                  ZIP
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    isInvalid={errors.zip}
                    type="text"
                    placeholder="ZIP"
                    name="zip"
                    ref={register}
                  />
                </Col>
              </Form.Group>
            }
          <Form.Group as={Row} key="expiraryDate" controlId="expiraryDate">
            <Form.Label column sm="2">
              Expiray Date
             </Form.Label>
            <Col sm="1">
              <Form.Control
                type="text"
                placeholder="MM"
                isInvalid={errors.expiraryMonth}
                name="expiraryMonth"
                ref={register}
              />
            </Col>
            <Col sm=".5">
              <span>:</span>
            </Col>
            <Col sm="1">
              <Form.Control
                type="text"
                isInvalid={errors.expiraryYear}
                placeholder="YY"
                name="expiraryYear"
                ref={register}
              />
            </Col>
          </Form.Group>
        </div> : paymentType === 'ACH' ? <div>
          <Form.Group as={Row} key="accountingNumber" controlId="accountingNumber">
            <Form.Label column sm="2">
              Accounting Number
         </Form.Label>
            <Col sm="10">
              <Form.Control
                type="text"
                placeholder="Accounting Number"
                name="accountingNumber"
                ref={register}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} key="routingNumber" controlId="routingNumber">
            <Form.Label column sm="2">
              Routing Number
         </Form.Label>
            <Col sm="10">
              <Form.Control
                type="text"
                placeholder="Routing Number"
                name="routingNumber"
                ref={register}
              />
            </Col>
          </Form.Group>
        </div> : <></>
      }
      <Button
        variant="primary"
        disabled={bookingResult === 'LOADING'}
        className="btn-submit"
        onClick={handleSubmit(onSubmit)}
      >
        Pay and Book now
      </Button>
    </Form>
  );
}
